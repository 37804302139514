import React from "react";
import Stack from "react-bootstrap/Stack";
import ModuleTitle from '@components/modules/Title';
import moment from 'moment';
export const numberFormat = num => {
    if (!num) return 0
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/format
    return new Intl.NumberFormat("en-US", {}).format(num)
}
const SpecificationItem = ({ item, keySpec }) => {
    return (
        <>
            <div className="property-details-specification-list-item">
                <span className="specification-key">{keySpec}</span>
                <span className="specification-value">{item}</span>
            </div>
            <div className="divider" />
        </>
    )
}

const PropertySpecification = ({ currency, completion_status, owner_ship_term, title, data, className, department, building, bedroom, completionDate, developer_name, price, max_price, QRCodes, region}) => {
    const priceFormat = price && !Number.isNaN(price)
        ? new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'AED',
            minimumFractionDigits: 1,
            maximumFractionDigits: 4,
            minimumSignificantDigits: 1,
            maximumSignificantDigits: 4,
        }).format(price)
        : '';
    const max_priceFormat = max_price && !Number.isNaN(max_price)
        ? new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'AED',
            minimumFractionDigits: 1,
            maximumFractionDigits: 4,
            minimumSignificantDigits: 1,
            maximumSignificantDigits: 4,
        }).format(max_price)
        : '';

    if(department == "international_projects"){
        if(region == "United Kingdom"){
            currency = "GBP"
        }
        else if(region == "Europe"){
            currency = "EUR"
        }
    }
    return (
        <>
            <Stack className={`gap-16 gap-md-32 property-details-specification${className ? ' ' + className : ''}`}>
                {title &&
                    <ModuleTitle
                        as="h5"
                        title={title}
                        className="property-details-info-title"
                    />
                }
                <Stack className="property-details-specification-list gap-8 gap-md-16">
                    {department === "new_developments" || department === "new_development_projects" || department === "international_projects" ?
                        <>
                            {bedroom && building && <SpecificationItem item={bedroom + " bedroom " + building} keySpec="Type:" />}
                            {max_price ? <SpecificationItem item={department === "international_projects" ? `${currency ? currency + ' ' + numberFormat(price) : numberFormat(price)} - ${currency ? currency + ' ' + numberFormat(max_price) : numberFormat(max_price)}` : `${priceFormat}-${max_priceFormat}`} keySpec="Price Range:" />
                                : priceFormat ? <SpecificationItem item={department === "international_projects" ? `${currency ? currency + ' ' + numberFormat(price) : numberFormat(price)}` : `${priceFormat}`} keySpec="Price:" /> : null}
                            {owner_ship_term && <SpecificationItem item={owner_ship_term} keySpec="Ownership Tile:" />}

                            {developer_name && <SpecificationItem item={developer_name} keySpec="Developer:" />}
                            {completion_status && department === "international_projects" && <SpecificationItem item={completion_status === "Completed" ? "Already Completed" : completion_status} keySpec="Completion:" />}
                            {completionDate && department === "new_developments" || department === "new_development_projects" && <SpecificationItem item={moment(completionDate).format("MMMM YYYY")} keySpec="Completion:" />}
                            {data?.numberoffloors &&
                                <SpecificationItem item={data?.numberoffloors} keySpec="Total Floors:" />
                            }
                            {data?.servicecharge &&
                                <SpecificationItem item={data?.servicecharge} keySpec="Expected Service Charge:" />
                            }
                            {data?.projected_roi &&
                                <SpecificationItem item={data?.projected_roi} keySpec="Projected ROI:" />
                            }
                            {data?.rera_permit_number &&
                                <SpecificationItem item={data?.rera_permit_number} keySpec="RERA Permit No:" />
                            }
                        </>
                        :
                        <>
                            {data?.furnished &&
                                <SpecificationItem item={data?.furnished} keySpec="Furnished:" />
                            }
                            {data?.floorarea_min &&
                                <SpecificationItem item={data?.floorarea_min + ' Sq.Ft'} keySpec="BUA:" />
                            }
                            {data?.city &&
                                <SpecificationItem item={data?.city } keySpec="City:" />
                            }
                            {data?.completion_status &&
                                <SpecificationItem item={data?.completion_status === "Completed" ? "Already Completed" : completion_status} keySpec="Completion:" />
                            }
                            {data?.referenceNumber &&
                                <SpecificationItem item={data?.referenceNumber} keySpec="Ref No:" />
                            }
                            {data?.rera_permit_number &&
                                <SpecificationItem item={data?.rera_permit_number} keySpec="RERA Permit No:" />
                            }
                            {data?.lotsize &&
                                <SpecificationItem item={data?.lotsize + ' Sq.Ft'} keySpec={"Plot Size:"} />
                            }
                            {developer_name && <SpecificationItem item={developer_name} keySpec="Developer:" />}
                        </>
                    }
                    {QRCodes && QRCodes.length > 0 &&
                        <>
                            {
                                QRCodes.map((logo, k) => {
                                    return (
                                        <div className="property-details-specification-list-item">
                                            <span className="specification-key">Permit QR Code:</span>
                                            <span className="specification-value"><img src={logo.url} key={k} alt="QR code" className="QR-code" /></span>
                                        </div>
                                    )
                                })
                            }
                            <div className="divider" />
                        </>
                    }
                </Stack>
            </Stack >
        </>
    )
}

export default PropertySpecification
