import React from "react";
import ReviewCard from '@components/reviews/ReviewCard';
import Button from "react-bootstrap/Button";
import Modal from '@components/modal';

const ReviewCardWrapper = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    return(
        <>
            <Button variant="none" className="d-block p-0 text-start" onClick={() => setModalShow(true)}>
                <ReviewCard {...props} truncateText />
            </Button>
            <Modal
                className = {props.path === "uae" && "arabic_modal"}
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                <ReviewCard {...props} />
            </Modal>
        </>
    )
}

export default ReviewCardWrapper
