import React, { useState } from "react";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
// import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
// import Col from "react-bootstrap/Col";
import getVideoId from 'get-video-id';
import { StarberryIcons } from "@components/icons/index";
import { SwiperSlide } from 'swiper/react';
import Carousel from '@components/carousel';
import CustomVideo from "@components/PlayVideo/custom-video"
import FsLightbox from 'fslightbox-react';
import ModalPortal from '@components/modal/portal';
const sliderConfig = {
    slidesPerView: "auto",
    spaceBetween: 8,
    pagination: false,
    breakpoints: {
        992: {
            spaceBetween: 24
        }
    }
}

const PropertyCarouselButtons = ({ data, className, openLightbox, BannerImages }) => {
    const [lightboxController, setLightboxController] = useState(false);
    const openLightboxFloorPlan = () => {
        setLightboxController(!lightboxController);
    }

  // Video id
  // Virtual Tour
//   const virtualTourid = data?.virtual_tour && data?.virtual_tour.length > 0 && data?.virtual_tour[0]?.tags === "Virtual Tour" && data?.virtual_tour[0]?.url && getVideoId(data?.virtual_tour[0]?.url);

  // Community Video
//   const communityVideoid = data?.virtual_tour && data?.virtual_tour.length > 0 && (data?.virtual_tour[0]?.tags === "Community Video" || data?.virtual_tour[1]?.tags === "Community Video") && getVideoId(data?.virtual_tour[0]?.tags === "Community Video" ? data?.virtual_tour[0]?.url : (data?.virtual_tour[1]?.tags === "Community Video" ? data?.virtual_tour[1]?.url : ""));

    //   console.log("$$$$$--vid---", data?.virtual_tour)
  // Video id

    // Video
    // Virtual Tour
    const [showVTour, setShowVTour] = useState(false);
    const [fullscreenVTour, setFullscreenVTour] = useState(true);
  const [toururl, settoururl] = useState('')
    function handleShowVTour(url, breakpoint) {
        settoururl(url)
        setFullscreenVTour(breakpoint);
        setShowVTour(true);
        
    }

    // Community Video
    const [showCVideo, setShowCVideo] = useState(false);
    const [fullscreenCVideo, setFullscreenCVideo] = useState(true);
  
    function handleShowCVideo(url, breakpoint) {
        settoururl(url)
        setFullscreenCVideo(breakpoint);
        setShowCVideo(true);
    }
    // Video
    const imgLightbox = [...data?.floorplan]
    const arrImgs = imgLightbox?.map((img) => {
        return img.url
    })

    return(
        <div className="property-details-carousel-buttons">
            <Container fluid="lg">
                <Row>
                    <Col>
                        <Carousel className={`property-carousel-buttons${className ? ' ' + className : ''}`} {...sliderConfig}>
                            {BannerImages.length > 0 &&
                                <SwiperSlide>
                                    <Button variant="secondary" onClick={openLightbox}><StarberryIcons iconName="galleryIcon" className="bi" />Gallery ({BannerImages.length})</Button>
                                </SwiperSlide>
                            }
                            {data?.virtual_tour?.length > 0 && data?.virtual_tour?.map((item) => {
                                  const virtualTourid =  item?.url && getVideoId(item?.url);
                                  var isNewwindow = item.url?.indexOf("s3.amazonaws.com") > -1 ? true : false 
                                 return (
                                    virtualTourid?.service === "youtube" ||  virtualTourid?.service === "vimeo" ?
                                    <SwiperSlide>
                                        <CustomVideo 
                                            url={item?.url} 
                                            vtype={virtualTourid?.service} 
                                            videoTag={item?.tags === "Main Video" ? "PropertyVideo" : item?.tags === "Community Video" ? "CommunityVideo" : item.tags === "Virtual Tour" ? "VirtualTour" : ""}
                                        />
                                    </SwiperSlide>    
                                    : item.tags === "360tour" ?
                                    
                                    <SwiperSlide><Button variant="secondary" onClick={() => handleShowVTour(item.url)} ><StarberryIcons iconName="tourIcon" className="bi" />3D Virtual Tour</Button></SwiperSlide>
                                    :
                                    <SwiperSlide>
                                        <Button variant="secondary" onClick={() => isNewwindow ? window.open(item.url) :  item?.tags === "Main Video" ? handleShowVTour(item.url) : item?.tags === "Community Video" ? handleShowCVideo(item.url) : null}><StarberryIcons iconName="videoCircleIcon" className="bi" />{item?.tags === "Main Video" ? "Property Video" : item?.tags === "Main Video" ? "Community Video" : isNewwindow ? "Property Video" : "Property Video"}</Button>
                                    </SwiperSlide>
                                )
                                })}
                            {/* {
                                data?.virtual_tour?.length > 0 &&
                                data?.virtual_tour[0]?.tags === "Virtual Tour" &&
                                (
                                    virtualTourid?.service === "youtube" ||  virtualTourid?.service === "vimeo" ?
                                    <SwiperSlide>
                                        <CustomVideo 
                                            url={data?.virtual_tour[0]?.tags === "Virtual Tour" ? data?.virtual_tour[0]?.url : ""} 
                                            vtype={virtualTourid?.service} 
                                            videoTag={"VirtualTour"} 
                                        />
                                    </SwiperSlide>                                  
                                    :
                                    <SwiperSlide>
                                        <Button variant="secondary" onClick={ handleShowVTour}><StarberryIcons iconName="videoCircleIcon" className="bi" />Property Video</Button>
                                    </SwiperSlide>
                                )
                            }
                            {
                                data?.virtual_tour?.length > 0 &&
                                (data?.virtual_tour[0]?.tags === "Community Video" || data?.virtual_tour[1]?.tags === "Community Video") &&
                                (
                                    communityVideoid?.service === "youtube" ||  communityVideoid?.service === "vimeo" ?
                                    <SwiperSlide>
                                        <CustomVideo 
                                            url={(data?.virtual_tour[0]?.tags === "Community Video" ? data?.virtual_tour[0]?.url : (data?.virtual_tour[1]?.tags === "Community Video" ? data?.virtual_tour[1]?.url : ""))} 
                                            vtype={communityVideoid?.service} 
                                            videoTag="CommunityVideo" 
                                        />
                                    </SwiperSlide>
                                    :
                                    <SwiperSlide>
                                        <Button variant="secondary" onClick={handleShowCVideo}><StarberryIcons iconName="videoCircleIcon" className="bi" />Community Video</Button>
                                    </SwiperSlide>
                                )
                            }                             */}
                            
                            {
                                data?.floorplan?.length > 0 &&
                                <SwiperSlide><Button onClick={() => data?.floorplan[0]?.mimetype === "application/pdf" ? window.open(data?.floorplan[0]?.url) : openLightboxFloorPlan()} variant="secondary"><StarberryIcons iconName="floorplanIcon" className="bi" />Floorplan</Button></SwiperSlide>
                            }
                            {
                                data?.brochure?.length > 0 && data?.search_type !== "offplan" && 
                                <SwiperSlide><Button onClick={() => data?.brochure[0]?.mimetype === "application/pdf" ? window.open(data?.brochure[0]?.url) : openLightboxFloorPlan()} variant="secondary"><StarberryIcons iconName="floorplanIcon" className="bi" />Brochure</Button></SwiperSlide>
                            }
                        </Carousel>
                    </Col>
                </Row>
            </Container>

            {data?.floorplan?.length > 0 &&
            <ModalPortal>
                                    <FsLightbox
                                        toggler={lightboxController}
                                        sources={arrImgs}
                                        type="image"
                                        customAttributes={[
                                            {
                                            alt: 'Property Carousel Image - Exclusive Links'
                                            }
                                            ]}
                                    />
                                </ModalPortal>
}

            {/* Video modal */}
            {/* Virtual Tour */}
            <Modal
                show={showVTour}
                fullscreen={fullscreenVTour}
                onHide={() => setShowVTour(false)}
                dialogClassName="modal-fullscreen property-details-modal"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <iframe src={toururl}></iframe>
                </Modal.Body>
            </Modal>

            {/* Community Video */}
            <Modal
                show={showCVideo}
                fullscreen={fullscreenCVideo}
                onHide={() => setShowCVideo(false)}
                dialogClassName="modal-fullscreen property-details-modal"
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <iframe 
                        src={toururl}
                    >
                    </iframe>
                </Modal.Body>
            </Modal>
            {/* Video modal */}

        </div>
    )
}

export default PropertyCarouselButtons
