import React from "react";
import ModuleSection from '@components/modules/moduleWrapper';
import { SwiperSlide } from 'swiper/react';
import Carousel from '@components/carousel';
import ReviewCard from '@components/modules/Reviews/reviewCard';
import ModuleText from '@components/modules/Text';
import ModuleTitle from '@components/modules/Title';
import {Row, Col, Button} from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { StarberryIcons } from "@components/icons/index";
import { ModalConsumer } from '@components/modal/ModalContext';
import { LeaveReviewModal } from '@components/modal/modals';
import Loader from '@components/loader';

import { fadeIn } from '@components/utils/animations';

import '../../components/modules/Reviews/reviews.scss';

const ReviewsSlider = ({ title,text, reviews, loading, error}) => {
    const sliderConfig = {
        spaceBetween: 40,
        pagination: {
            clickable: true,
            dynamicBullets: true,
          },
        navigation: true,
        breakpoints: {
            // when window width is >= 768px            
            768: {
              slidesPerView: 1,
              spaceBetween: 16
            },
            992: {
              slidesPerView: 2,
              spaceBetween: 40
            },
            1400: {
                slidesPerView: 2,
                spaceBetween: 40
            }
           
        }
    }

    return (
         <>
         {reviews?.length > 0 ? 
            <ModuleSection sectionClass={`module reviews-slider`}>
            <StaticImage
                src="../../../images/pattern.jpg"
                quality={50}
                formats={["auto", "webp"]}
                alt="Background Image - Exclusive Links"
                className="pattern-img"
            />
            <Row className="position-relative z-index-5">
                <Col xs={12} lg={3}>
                    {title &&
                        <div className="mb-16 mb-md-24 mb-lg-32" variants={fadeIn}>
                            <ModuleTitle
                                title={title}
                                className="text-white module__title"
                            />
                        </div>
                    }
                    {text &&
                        <ModuleText
                            text={text}
                            className="module__text text-white d-block mb-24 mb-md-40"
                        />
                    }
                    <div className="mb-32 mb-md-24 mb-md-48 mb-lg-0" variants={fadeIn}>
                        <ModalConsumer>
                            {({ showModal }) => (
                                <Button variant="secondary" onClick={() => showModal(LeaveReviewModal, { show: true })}><StarberryIcons iconName="plusIcon" className="bi" />Leave a Review</Button>
                            )}
                        </ModalConsumer>
                    </div>
                </Col>
                <Col xs={12} lg={{ span: 8, offset: 1}}>
                    <Loader loading={loading} error={error}>
                        <Carousel className="reviews-carousel" {...sliderConfig}>
                            {reviews?.map((review, i) => {
                                return(
                                    <SwiperSlide key={review.id}>
                                        <ReviewCard review={review} className="reviews-slider__item" custom={i} />
                                    </SwiperSlide>
                                )
                            })}
                        </Carousel>
                    </Loader>
                </Col>
            </Row>
        </ModuleSection>
    : null
        }
    </>
    
    )
}

export default ReviewsSlider

