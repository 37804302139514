import React from "react";
import TotalReviewsCount from "@components/ReviewsComponent/TotalReviewsCount"
export const numberFormat = num => {
    if (!num) return 0
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/format
    return new Intl.NumberFormat("en-US", {}).format(num)
  }
const PropertyPrice = ({ propertyId, currency, price, className, as, department, max_price, international, price_qualifier, search_type, region }) => {
    const As = as ? as : "div";
    const priceFormat = price && !Number.isNaN(price)
    ? new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'AED',
        minimumFractionDigits: 1,
        maximumFractionDigits: 4,
        minimumSignificantDigits: 1,
        maximumSignificantDigits: 4,
    }).format(price)
    : '';
    const max_priceFormat = max_price && !Number.isNaN(max_price)
    ? new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency: 'AED',
        minimumFractionDigits: 1,
        maximumFractionDigits: 4,
        minimumSignificantDigits: 1,
        maximumSignificantDigits: 4,
    }).format(max_price)
    : '';

    if(department == "international_projects"){
        if(region == "United Kingdom"){
            currency = "GBP"
        }
        else if(region == "Europe"){
            currency = "EUR"
        }
    }
    const cssClass = (propertyId && department === "short_term" && 'price-info' )
    return(
        <As className={`${className} ${cssClass}`}>
            <div className="price-details">
            {max_price ?
            <>{department === "new_development_projects" ? "Price Range " : department === "international_projects" ? "" :  ""} {department === "international_projects" ? <><>{currency ? currency +' '+ numberFormat(price) : numberFormat(price)}</> - <>{currency ? currency +' '+ numberFormat(max_price) : numberFormat(max_price)}</></> : priceFormat +' - '+ max_priceFormat}</>
            : price ? <>
            {department === "international_projects" ? <>{currency ? currency +' '+ numberFormat(price) : numberFormat(price)}</> : <>{priceFormat} {search_type === "lettings" ? price_qualifier : ""}</>}
            </>
            : ""
            }
            </div>
            {propertyId && department === "short_term" &&
                <TotalReviewsCount propertyId ={propertyId}/>
            }
        </As>
    )
}

export default PropertyPrice
