import React from "react";
import { StarberryIcons } from "@components/icons/index";
import BrandLogo from "../../images/logo.svg";
import { Stack } from "react-bootstrap";

const LoadingAnim = ({ hideText }) => {
    return(
        <section className={"loader-component p-16 p-md-32"}>
            <Stack className="gap-24 align-items-center">
                <StarberryIcons iconName="logoSignIcon"  className="sign-logo-anim" />
                {!hideText &&
                    <div className="loader-component-text">Loading...</div>
                }
            </Stack>
        </section>
    )
}

const ErrorAnim = ({ hideText }) => {
    return(
        <section className={"loader-component error p-16 p-md-32"}>
            <Stack className="gap-24 align-items-center">
                <StarberryIcons iconName="logoSignIcon"  className="sign-logo-anim" />
                {!hideText &&
                    <div className="loader-component-text">Error Loading...</div>
                }
            </Stack>
        </section>
    )
}

const Loader = ({ loading, error, children, hideText, fullScreen }) => {
    if (fullScreen) {
        if (loading) return (
            <section className={"loader-wrapper"}>
                <div id="loader-wrapper">
                    <div id="loader" className="new-loader">
                        <img className="logo-white loader-logo" src={BrandLogo} alt="logo" />
                    </div>
                </div>
            </section>
        )
    }
    if (loading) return <LoadingAnim loading={loading} hideText={hideText} />;
    if (error) return <ErrorAnim error={error} hideText={hideText} />;

    return(
        <>
            {children}
        </>
    )
}

export default Loader