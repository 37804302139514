import gql from "graphql-tag"

const People_Email = gql`
query GetPeopleEmail($email_id: String!){
    teams(where:{Email:$email_id}){
        id
        Name
        Email
        Phone
        JobTitle
        Slug
        Department
        Image {
          url
          alternativeText
        }
        imagetransforms
    }
}`

export const Queries = {
    People_Email
};