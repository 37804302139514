import React, { useState, useEffect } from "react";
import Stack from "react-bootstrap/Stack";
import PropertyImage from '@components/property/image';
import PropertyAddress from '@components/property/address';
import PropertyPrice from '@components/property/price';
import PropertyRooms from '@components/property/rooms';
import PropertyFloorArea from '@components/property/floorArea';
import PropertyPhone from '@components/property/propertyPhone';
import PropertyWhatsApp from '@components/property/propertyWhatsApp';
import { Link } from 'gatsby';
import { delayItemsFromBottom } from '@components/utils/animations';
import NoImage from '../../../images/no-image.png';
import './propertyCard.scss';
// let text_truncate = function(str, length, ending) {
//     length = 150;
//     //

//     if (ending == null) {
//       ending = '...';
//     }
    
//     if (str.length > length) {
//       return str.substring(0, length - ending.length) + ending;
//     } else {
//       return str;
//     }
//   };
const PropertyCard = ({ property, className, custom, international }) => {

    // const [renderComponent, setRenderComponent] = useState(false);

    // useEffect(() => {
    //   window.addEventListener("mousemove", () => {
    //     if (renderComponent === false) {
    //       setRenderComponent(true)
    //     }
    //     })
    //     window.addEventListener("touchmove", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    //     window.addEventListener("keypress", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    // }, [])
    const {
        id,
        // publish,
        bedroom,
        bathroom,
        display_address,
        // long_description,
        // address,
        extra,
        negotiator,
        price,
        slug,
        max_price,
        department,
        description,
        imagetransforms,
        images,
        search_type,
        price_qualifier,
        building,
        // area,
        currency
    } = property

    // property details url structure
    let uriStr = "";

    if (search_type === "sales" && department === 'residential') {
      uriStr = `property-for-sale/`
    } else if (search_type === "lettings" && department === 'residential') {
      uriStr = `property-for-rent/`
    }  else if (search_type === "lettings" && department === 'short_term') {
      uriStr = `short-term-property-for-rent/`
    } else if (search_type === "sales" && department === 'commercial') {
      uriStr = `commercial-property-for-sale/`
    } else if (search_type === "lettings" && department === 'commercial') {
      uriStr = `commercial-property-for-rent/`
    } else if (search_type === "sales" && department === 'new_developments') {
        uriStr = `off-plan-property-for-sale/`
      } else if (search_type === "offplan" && department === 'new_development_projects') {
        uriStr = `off-plan-project-for-sale/`
      } else if (search_type === "international" && department === 'international_projects') {
        uriStr = `international-property-for-sale/`
      }
      let processedImages = JSON.stringify({});
      if (imagetransforms?.images_Transforms) {
          processedImages = imagetransforms?.images_Transforms;
      }
    // property details url structure
    return(
        <div
            className={className}
            variants={delayItemsFromBottom}
            custom={custom}
        >
            <div
                className="property-card"
            >
                {images.length > 0 ?
                    <Link className="" to={`/${uriStr}${slug}-${id}/`}>
                            <PropertyImage
                                images={images}
                                processedImages={processedImages}
                                id={id}
                                className="property-card-image"
                            />
                    </Link>
                    :
                    <Link className="" to={`/${uriStr}${slug}-${id}/`}>
                        <PropertyImage
                            images={NoImage}
                            NoImage={true}
                            processedImages={processedImages}
                            className="property-card-image"
                        />
                </Link>
                    
                }
                <Stack direction="vertical" className="gap-8 property-card-text">
                    {description &&
                        <Link className="" to={`/${uriStr}${slug}-${id}/`}>
                            <PropertyAddress
                                address={description}
                                className="property-card-address text-truncate"
                            />
                        </Link>
                    }
                    {price &&
                        <PropertyPrice
                            propertyId ={id}
                            price={price}
                            max_price={max_price}
                            department={department}
                            className="property-card-price"
                            international={international}
                            price_qualifier={price_qualifier}
                            search_type={search_type}
                            currency={currency}
                        />
                    }
                    {international && display_address ?
                    <div className="property-card-address-address text-truncate">{display_address}</div>
: null}
                    {display_address && department !== 'international_projects' &&
                        <div className="property-card-address-address text-truncate">{display_address}</div>
                    }
                      {/* {
                                  long_description && department === 'new_developments' &&
                                  <Stack className="gap-16 mt-20">{text_truncate(long_description)}</Stack>
                                } */}
                    <Stack direction="horizontal" className={!bathroom && department === "commercial" ? "mt-8 mt-md-16" : "gap-26 mt-8 mt-md-16"}>
                        <PropertyRooms
                            bedroom={bedroom}
                            bathroom={bathroom}
                            className="property-card-rooms"
                            department={department}
                            building={building}
                        />
                        {extra?.floorarea_min ?
                            <PropertyFloorArea
                            department={department}
                                floorarea={extra.floorarea_min}
                                floorarea_type={extra.floorarea_type}
                                className="property-card-floorArea"
                            />
                            : null
                        }
                    </Stack>
                    <Stack direction="horizontal" className="gap-16 mt-16 d-flex d-sm-none">
                        <PropertyPhone phone={negotiator?.phone} />
                        <PropertyWhatsApp  className="flex-grow-1" />
                    </Stack>
                </Stack>
            </div>
        </div>
    )
}

export default PropertyCard
