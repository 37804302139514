import React from "react";
import ModuleSection from '@components/modules/moduleWrapper';
import { SwiperSlide } from 'swiper/react';
import Carousel from '@components/carousel';
import PropertyCard from '@components/property/PropertyCard';

import { fadeIn } from '@components/utils/animations';

import '@components/modules/PropertySlider/propertySlider.scss';
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
const GET_ALL_OFFPLAN = gql`
    query GetOffplanProperties($relation_ref: String) {
        properties(where: {relation_ref: $relation_ref, publish: true, department: "new_developments"}) {
            id,
            bedroom,
            bathroom,
            display_address,
            long_description,
            address,
            extra,
            price,
            slug,
            max_price,
            department,
            description,
            images,
            search_type,
            relation_ref    
        }
    }
`;
const sliderConfig = {
    slidesPerView: 1,
    spaceBetween: 32,
    breakpoints: {
        // when window width is >= 768px
        768: {
          slidesPerView: 2,
          spaceBetween: 16
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 32
        }
    }
}

const SimilarOffplanProjects = (props) => {
    const { loading, error, data } = useQuery(GET_ALL_OFFPLAN, { variables: { relation_ref: props.propertyData?.relation_ref } });
    // const [propItems, setPropItems] = useState([])
    // const params = {
    //     relation_ref: props.propertyData?.relation_ref
    // }
    // debugger
    // const getitems = async url => {
    //     try {
    //         const { data } = await axios.get(url, { params : {
    //             relation_ref: props.propertyData?.relation_ref
    //         } })
    //         // console.log("PropertySimilar", data)
    //         setPropItems(data)
    //     } catch (error) {
    //         // console.error(error)
    //     }
    // }

    // useEffect(() => {
        
    //     let url =
    //     process.env.GATSBY_STRAPI_SRC +
    //     "/stb-lists/item/offplan_properties_under_projects"
        

    //     getitems(url)
    // }, []);

    return (
        data?.properties?.length > 0 &&
        <ModuleSection
            sectionClass={`property-slider swiper-overflow-hidden${props?.moduleClass ? ` ${props.moduleClass}` : ''}`}
            sectionSpacing={props?.sectionSpacing}
        >
            <h3 className="mb-16 mb-md-24 mb-lg-32 property-slider-similar__title" variants={fadeIn}>
            Properties for Sale in this Project
            </h3>
            <Carousel className="properties-carousel" {...sliderConfig}>
                {data?.properties?.map((item, i) => {
                    return (
                        <SwiperSlide key={i}>
                            <PropertyCard property={item} className="properties-carousel__item" custom={i} />
                        </SwiperSlide>
                    )
                })}
            </Carousel>
        </ModuleSection>
    )
}

export default SimilarOffplanProjects;