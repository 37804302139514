import React, { useState, useEffect } from "react";
import axios from "axios";
import ModuleSection from '@components/modules/moduleWrapper';
import { SwiperSlide } from 'swiper/react';
import Carousel from '@components/carousel';
import PropertyCard from '@components/property/PropertyCard';

import { fadeIn } from '@components/utils/animations';

import '@components/modules/PropertySlider/propertySlider.scss';

const sliderConfig = {
    slidesPerView: 1,
    spaceBetween: 32,
    breakpoints: {
        // when window width is >= 768px
        768: {
          slidesPerView: 2,
          spaceBetween: 16
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 32
        }
    }
}

const SimilarProperties = (props) => {
    const [propItems, setPropItems] = useState([])
    const [propertyId, setPropertyId] = useState( props?.propertyData?.strapiId ? props?.propertyData?.strapiId : props?.propertyData?.id);

    const getitems = async url => {
        try {
            const { data } = await axios.get(url, {
                headers: {
                Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
                }})
            // console.log("PropertySimilar", data)
            setPropItems(data)
        } catch (error) {
            // console.error(error)
        }
    }

    useEffect(() => {
        //console.log('props',props?.propertyData);
        let url =
        process.env.GATSBY_STRAPI_SRC +
        "/stb-lists/item/Property-details?pid=" +
        propertyId

        getitems(url)
    }, [propertyId]);

    return (
        propItems.length > 0 &&
        <ModuleSection
            sectionClass={`property-slider swiper-overflow-hidden${props?.moduleClass ? ` ${props.moduleClass}` : ''}`}
            sectionSpacing={props?.sectionSpacing}
        >
            <h3 className="mb-16 mb-md-24 mb-lg-32 property-slider-similar__title" variants={fadeIn}>
                Similar {props.department === "new_developments" ? "off plan" : props.department === "commercial" ? "commercial" : ""} properties that may interest you...
            </h3>
            <Carousel className="properties-carousel" {...sliderConfig}>
                {propItems.map((item, i) => {
                    return (
                        <SwiperSlide key={i}>
                            <PropertyCard property={item} className="properties-carousel__item" custom={i} />
                        </SwiperSlide>
                    )
                })}
            </Carousel>
        </ModuleSection>
    )
}

export default SimilarProperties;