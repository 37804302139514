import React from "react";
import Button from 'react-bootstrap/Button';
import { StarberryIcons } from "@components/icons/index";

const PropertyPhone = ({ className, phone }) => {
    return(
        <Button className={className} href={`tel:${phone}`} variant="gray">
            <StarberryIcons iconName="phoneIcon" className="me-5 mt-n4" /> <span>Call</span>
        </Button>
    )
}

export default PropertyPhone
