import React, { useState, useEffect } from "react"
import Stack from "react-bootstrap/Stack";
import { StarberryIcons } from "@components/icons/index";
import { StaticImage } from "gatsby-plugin-image";
import ButtonLink from '@components/ButtonLink';
import { useLocation } from "@reach/router";

import './mortgageModule.scss';

const PropertyMorgageModule = ({ className }) => {
    const location = useLocation();
    var page_url = location.href;
    // const [renderComponent, setRenderComponent] = useState(false);
    // useEffect(() => {
    //   window.addEventListener("mousemove", () => {
    //     if (renderComponent === false) {
    //       setRenderComponent(true)
    //     }
    //     })
    //     window.addEventListener("touchmove", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    //     window.addEventListener("keypress", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    // }, [])
    return(
        <div className={`property-mortgage-module${className ? ' ' + className : ''}`}>
                <StaticImage
                 src="../../../images/pattern.jpg"
                formats={["auto", "webp"]}
                alt="Background Image - Exclusive Links"
                className="pattern-img"
                />

            <Stack className="gap-16 gap-md-24 p-24 p-md-32 flex-md-row align-items-md-center">
                {page_url?.includes("international-property") ?
                    <>
                    <StarberryIcons iconName="mortgageIcon" className="d-none d-md-block" />
                            <ButtonLink manualLink={"/contact-exclusive-links/our-head-office/"} variant="none" btnClass="property-mortgage-module-title mt-lg-7">
                                <u>Click here</u> to understand the <strong>costs of buying property in Dubai</strong> versus overseas
                            </ButtonLink>
                    <StarberryIcons iconName="rightArrowIcon" className="d-none d-md-block ms-md-auto" />
                    <ButtonLink manualLink={"/contact-exclusive-links/our-head-office/"} label="Calculate Your Payments" variant="secondary" btnClass="d-md-none" />
                    </>
                    : page_url?.includes("short-term-property") ?
                    <>
                    <StarberryIcons iconName="mortgageIcon" className="d-none d-md-block" />
                            <ButtonLink manualLink={"/contact-exclusive-links/our-head-office/"} variant="none" btnClass="property-mortgage-module-title mt-lg-7">
                                <u>Click here</u> if you want to know the <strong>cost advantages of short term</strong> rentals
                            </ButtonLink>
                    <StarberryIcons iconName="rightArrowIcon" className="d-none d-md-block ms-md-auto" />
                    <ButtonLink manualLink={"/contact-exclusive-links/our-head-office/"} label="Calculate Your Payments" variant="secondary" btnClass="d-md-none" />
                    </>
                    : page_url?.includes("/property-for-rent/") ?
                    <>
                    <StarberryIcons iconName="mortgageIcon" className="d-none d-md-block" />
                            <ButtonLink manualLink={"/rent-a-property-in-uae/tenants/tenants-guide/"} variant="none" btnClass="property-mortgage-module-title mt-lg-7">
                                <u>Click here</u> to read our tenant's guide and <strong>costs associated with renting</strong>
                            </ButtonLink>
                    <StarberryIcons iconName="rightArrowIcon" className="d-none d-md-block ms-md-auto" />
                    <ButtonLink manualLink={"/contact-exclusive-links/our-head-office/"} label="Calculate Your Payments" variant="secondary" btnClass="d-md-none" />
                    </>
                    : page_url?.includes("/off-plan-project-for-sale/") ?
                    <>
                    <StarberryIcons iconName="mortgageIcon" className="d-none d-md-block" />
                            <ButtonLink manualLink={"/contact-exclusive-links/our-head-office/"} variant="none" btnClass="property-mortgage-module-title mt-lg-7">
                                <u>Click here</u> to understand what <strong>payment plans are available</strong> to suit you.
                            </ButtonLink>
                    <StarberryIcons iconName="rightArrowIcon" className="d-none d-md-block ms-md-auto" />
                    <ButtonLink manualLink={"/contact-exclusive-links/our-head-office/"} label="Calculate Your Payments" variant="secondary" btnClass="d-md-none" />
                    </>
                    :
                    <>
                    <StarberryIcons iconName="mortgageIcon" className="d-none d-md-block" />
                            <ButtonLink manualLink={"https://www.mortgagefinder.ae/en/mortgage-enquiry?ref=90430509cd0305c6bdc69225db9f4b6ddb31274e&utm_source=Affiliate&utm_campaign=5105348&utm_medium=affiliate-href&utm_content="} variant="none" btnClass="property-mortgage-module-title mt-lg-7">
                                <u>Click here</u> to calculate your mortgage repayments with our <strong>Mortgage Calculator</strong>
                            </ButtonLink>
                    <StarberryIcons iconName="rightArrowIcon" className="d-none d-md-block ms-md-auto" />
                    <ButtonLink manualLink={"https://www.mortgagefinder.ae/en/mortgage-enquiry?ref=90430509cd0305c6bdc69225db9f4b6ddb31274e&utm_source=Affiliate&utm_campaign=5105348&utm_medium=affiliate-href&utm_content="} label="Calculate Your Payments" variant="secondary" btnClass="d-md-none" />
                    </>
                }
            </Stack>
        </div>
    )
}

export default PropertyMorgageModule
