import React from "react";
import Stack from "react-bootstrap/Stack";
import { StarberryIcons } from "@components/icons/index";

const PropertyFloorArea = ({ floorarea,floorarea_type, className, as, department }) => {
    const As = as ? as : "div";
    const areaFormat = floorarea && !Number.isNaN(floorarea)
    ? new Intl.NumberFormat('en-GB', {
        // minimumFractionDigits: 0,
        // maximumFractionDigits: 0,
    }).format(floorarea)
    : '';

    return(
        <>
            {floorarea && department === "new_developments" ? null
            : floorarea ?
                <As className={className}>
                    <Stack direction="horizontal" className="gap-14 align-items-baseline">
                        <StarberryIcons iconName="floorIcon" className="" />
                        <span>{areaFormat} Sq.Ft</span>
                    </Stack>
                </As>
                : null
            }
        </>
    )
}

export default PropertyFloorArea
