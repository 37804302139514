import React from 'react';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Stack from 'react-bootstrap/Stack';
import ModuleTitle from '@components/modules/Title';
import { StaticImage } from "gatsby-plugin-image";
import { StarberryIcons } from "@components/icons/index";

import { containerVariants, contentItemStagger, contentItem } from '@components/utils/animations';

import "./image.scss";


const PropertyDetailsBanner = ({ className, ItemTitle, Buttons, price, afterTitle }) => {
    let back_url = '/properties/for-sale/in-dubai';
    if (typeof window !== 'undefined' && localStorage.getItem('back_url') != null) {
        back_url = localStorage.getItem('back_url');
    }
    const handleBack = () => window.location = back_url;

    return (
        // <InView {...inViewOptions}>
        //     {({ ref, inView }) => (
                <section
                    className={`section image-banner property-details-banner${className ? ' ' + className : ''}`}
                    // ref={ref}
                    // initial="hidden"
                    // animate={inView ? "visible" : "hidden"}
                    // variants={containerVariants}
                >
                    <div
                        className="overlay position-relative"
                        variants={contentItemStagger}
                    >

                        <StaticImage
                            src="../../../images/pattern.jpg"
                            quality={50}
                            formats={["auto", "webp"]}
                            alt="Background Image - Exclusive Links"
                            className="pattern-img"
                        />

                        <Container fluid="lg">
                            <Row>
                                <Col xs={12}>
                                    <div className="image-banner__content">
                                        <div variants={contentItem} className="back-btn mb-24 mb-lg-40">
                                            <Button variant="link-secondary" onClick={handleBack} onKeyPress={handleBack} role="presentation"><StarberryIcons iconName="leftChevronIcon" className="bi" /><span>Back</span><span className="d-none d-md-inline"> to Search Results</span></Button>
                                        </div>
                                        <Stack className="gap-8 gap-md-24 gap-xl-60 flex-xl-row">
                                            <Stack className="gap-8 gap-md-24">
                                                {ItemTitle &&
                                                    <div variants={contentItem}>
                                                        <ModuleTitle
                                                            title={ItemTitle}
                                                            as="h1"
                                                            className="h6 text-white image-banner__title mb-8 mb-md-0"
                                                        />
                                                    </div>
                                                }
                                                {price &&
                                                    <div className="image-banner__text text-white" variants={contentItem}>
                                                        {price}
                                                    </div>
                                                }
                                                {afterTitle &&
                                                    <div className="image-banner__after-title">{afterTitle}</div>
                                                }
                                            </Stack>
                                            <div>
                                                {Buttons &&
                                                    <div className="image-banner__after-btns">{Buttons}</div>
                                                }
                                            </div>
                                        </Stack>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </section>
        //     )}
        // </InView>
    )
}

export default PropertyDetailsBanner