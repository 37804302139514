import React from "react";
import { GET_ALL_REVIEWS } from "@components/gql";
import { useQuery } from '@apollo/client';

import ReviewsSlider from  "./ReviewsSlider";

import "./style.scss";

const PropReviewsList = ({ propertyId }) => {

    const { loading, error, data } = useQuery(GET_ALL_REVIEWS, {
        variables: { propertyId },
    });
    
    return(
        <ReviewsSlider title="What do our guests think?" reviews={data?.reviews} loading={loading} error={error} />
    )
};

export default PropReviewsList;