import React from "react";
import Stack from "react-bootstrap/Stack";
import { StarberryIcons } from "@components/icons/index";

const PropertyRooms = ({ bedroom, bathroom, className, department, building, num_beds }) => {

    return(
        <Stack direction="horizontal" className={`gap-26 gap-lg-26 ${className}`}>
            {bedroom && department === "new_developments" ?
                <Stack direction="horizontal" className="gap-14 align-items-baseline">
                    <StarberryIcons iconName="bedroomIcon" className="" />
                    <span>{bedroom} bedroom {building}</span>
                </Stack>
                :   num_beds === "Studio" ?
                <Stack direction="horizontal" className="gap-14 align-items-baseline">
                <StarberryIcons iconName="bedroomIcon" className="" />
                <span>{num_beds}</span>
            </Stack> :
                bedroom ?              
                <Stack direction="horizontal" className="gap-14 align-items-baseline">
                <StarberryIcons iconName="bedroomIcon" className="" />
                <span>{bedroom}</span>
            </Stack>
            : null
            }
            {bathroom && department === "new_developments" ? null
            : bathroom ?

                <Stack direction="horizontal" className="gap-14 align-items-baseline">
                    <StarberryIcons iconName="bathroomIcon" className="" />
                    <span>{bathroom}</span>
                </Stack>
                : null
            }
        </Stack>
    )
}

export default PropertyRooms
