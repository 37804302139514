import React from "react";
import Stack from "react-bootstrap/Stack";
import ModuleTitle from '@components/modules/Title';

const PropertyFeatures = ({ title, data, className }) => {
    return(
        <>
            <Stack className={`gap-16 gap-md-32 property-details-features${className ? ' ' + className : ''}`}>
                {title &&
                    <ModuleTitle
                        as="h5"
                        title={title}
                        className="property-details-info-title"
                    />
                }
                <ul className="property-details-features-list">
                    {data.map((item, i) => (
                        <li key={i}>{item}</li>
                    ))}
                </ul>
            </Stack>
        </>
    )
}

export default PropertyFeatures
