import React from "react";
import Button from 'react-bootstrap/Button';
import { StarberryIcons } from "@components/icons/index";
import { onlyNumbers } from '@components/utils/CleanedHtml';
const PropertyWhatsApp = ({ className }) => {
    return(
        <Button className={`btn-icon-whatsApp ${className}`} href={`https://wa.me/${onlyNumbers("97143994937")}`} variant="gray">
            <StarberryIcons iconName="whatsAppIcon" className="me-5 mt-n4" /> <span>WhatsApp</span>
        </Button>
    )
}

export default PropertyWhatsApp
