import React,{useState, useRef} from "react";
import Stack from "react-bootstrap/Stack";
import ModuleTitle from '@components/modules/Title';

const PropertyDescription = ({ title, data, className }) => {
    const myRefless = useRef(null)
    
    const readlessScroll = () => {
        myRefless.current.scrollIntoView()
    }
    const [active, setActive] = useState('read more');
    const handleAccordionClick = () => {
        if (active === 'read more') {
            setActive('read less')
        } else {
            setActive('read more')
            readlessScroll()
        }
    }
    return(
        <>
            <Stack className={`gap-16 gap-md-32 property-details-description${className ? ' ' + className : ''}`} ref={myRefless}>
                {title &&
                    <ModuleTitle
                        as="h5"
                        title={title}
                        className="property-details-info-title"
                    />
                }
                <Stack className="property-details-specification-list gap-8 gap-md-16">
                    {/* {data} */}
                    <p>
                    {active === 'read more' ? <><span dangerouslySetInnerHTML={{__html:data?.replace('<br>', " ").replaceAll('<p>', "").replaceAll('</p>', " ").slice(0, 500)+'... '}} /> 
                              
                              </>

                              : <span dangerouslySetInnerHTML={{__html:data + " "}} /> }
                              {data?.length > 500 && <a href='javascript:void(0)' className="read-more btn-text-link" onClick={handleAccordionClick}><u>{active}</u></a>}
                              </p>
                </Stack>
            </Stack>
        </>
    )
}

export default PropertyDescription
