import React from "react";
import ImageTransform from "@components/common/ggfx-client/module/components/image-transform";
const PropertyImage = ({ images, className, as, NoImage, processedImages, id }) => {
    const As = as ? as : "div";
    const image = images[0];

    return(NoImage ?

        <As className={`container-fullsize ${className}`} style={{ backgroundImage: `url("${NoImage ? images : image.url}")` }}></As>
        : <ImageTransform
        imagesources={image?.url}
        renderer="bgImg"
        imagename="property.images.detailgallery"
        attr={{ alt: '', className: `container-fullsize ${className}`}}
        imagetransformresult={processedImages}
        id={id}
    /> 
    )
}

export default PropertyImage
