import * as React from "react"
import loadable from "@loadable/component";
import "./Map.scss"
// import GoogleSingleMap from "@components/maps/google/property-detail-map"
//import GoogleSingleMap from "@components/maps/google/place-and-direction";
const GoogleSingleMap = loadable(() => import("@components/maps/google/place-and-direction"));

// styles

// markup
const Map = ( props ) => {
let latitude = props?.property_details_map_data?.latitude ? props?.property_details_map_data?.latitude : ""
let longitude = props?.property_details_map_data?.longitude ? props?.property_details_map_data?.longitude : ""
if(props?.property_details_map_data?.algoliaData?._geoloc?.lat){
  latitude = props?.property_details_map_data?.algoliaData?._geoloc?.lat;
}
if(props?.property_details_map_data?.algoliaData?._geoloc?.lng){
  longitude = props?.property_details_map_data?.algoliaData?._geoloc?.lng;
}
  return (
    props?.property_details_map_data &&
    <React.Fragment>
      
      <div className="property-details-map-wrap">
        { latitude && longitude &&
          <GoogleSingleMap destination={props?.property_details_map_data?.display_address} zoom={props.zoom} title={props?.property_details_map_data?.description} className="property-details-map" lat={latitude} lng={longitude} height={"650px"}/>
        }
      </div>
    </React.Fragment>
  )
}

export default Map