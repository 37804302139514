import React from "react";
import Stack from "react-bootstrap/Stack";
import ModuleSection from '@components/modules/moduleWrapper';
import ModuleText from '@components/modules/Text';
import ModuleTitle from '@components/modules/Title';
import { StaticImage } from "gatsby-plugin-image";
import { fadeInFromTop, fadeIn } from '@components/utils/animations';
import BroucherForm from '@components/forms/broucher';

const Broucher = ({ brochure }) => {
    return(
        <ModuleSection
            removeContainer={false} 
            sectionClass={`management-broucher-module `}
            sectionSpacing={`pt-md-64  pt-lg-80 pt-40 pb-0`}
            id="broucher-form"
        >
         
            <Stack className="gap-16 gap-md-24 position-relative form-broucher">
            <StaticImage
                src="../../images/pattern.jpg"
                quality={50}
                formats={["auto", "webp"]}
                alt="Background Image - Exclusive Links"
                className="pattern-img"
            />
              <Stack className="gap-16 gap-md-24 position-relative z-index-5 broucher-section">

             
                    <div variants={fadeInFromTop}>
                        <ModuleTitle
                            as="h4"
                            title={`Request a Brochure`}
                            className="module__title"
                        />
                    </div>
               
               
                    <div variants={fadeInFromTop}>
                        <ModuleText
                            text={`To request a brochure on this project from a member of our off plan investment team, please enter your details and we will be in touch. And don’t worry, we’ll never share your details or spam you.`}
                            className="module__text white-text d-block mb-16"
                        />
                    </div>
                
                <div variants={fadeIn}>
                    <BroucherForm
                        Guide_Attachment={brochure}
                        title={'Brochure'}
                        // formClass="grey-fields"
                    />
                </div>
                </Stack>
            </Stack>
        </ModuleSection>
    )
}

export default Broucher
 