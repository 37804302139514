import React from "react";

const PropertyAddress = ({ address, className, as }) => {
    const As = as ? as : "h3";
    return(
        <As className={className}>
            {address}
        </As>
    )
}

export default PropertyAddress
