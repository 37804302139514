import React, { useState, useEffect, useRef } from "react"
import { Modal, Button } from "react-bootstrap"
//import loadable from "@loadable/component";
import 'react-modal-video/scss/modal-video.scss';
import ReactPlayer from 'react-player/lazy'
import { StarberryIcons } from "@components/icons/index";
import {
    isIOS,
    isMobile
} from "react-device-detect";
import "./custom-video.scss"
//const ReactPlayer = loadable(() => import("react-player"));

const CustomVideo = (props) => {
    //const [isOpen, setOpen] = useState(false);
    //console.log('showHTMLVideo',props.vtype, props.url)
     const trackerVideo = (event) => {   
        if(typeof window !== 'undefined'){ 
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
            'event': 'Video Play Btn',
            'formType': event,
            'formId': 'Video Play',
            'formName': 'Video',
            'formLabel': 'Video'
            });
        }
    }

  //   const [isPlay, setPlay] = useState(false);
  const vidRef = useRef(null)
  const [show, setShow] = useState(false)
  const [play, setPlayvideo] = useState(true)
  const [mute, setMute] = useState(true)
  const [controls, setVideoControls] = useState(false)

  const [showHTMLVideo, setHTMLVideo] = React.useState(false)
  const pausevideo = ref => {
    setPlayvideo(false)
    setVideoControls(false)
  }
  const playvideo = ref => {
    setPlayvideo(true)
    setVideoControls(false)
    // setTimeout(function(){ $(".modal-bg-black").hide() }, 1700);
  }
  const showControls = () => {
    setVideoControls(true)
  }

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => {
          setHTMLVideo(true)
          playvideo()
          setShow(true)
        }}
      >
        <StarberryIcons iconName="videoCircleIcon" className="bi" />
        {props.videoTag === "VirtualTour"
          ? "Video"
          : props.videoTag === "PropertyVideo"
          ? "Property Video"
          : props.videoTag === "CommunityVideo"
          ? "Community Video"
          : ""}
      </Button>
      {showHTMLVideo && props.vtype == "vimeo" ? (
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-video"
          aria-labelledby="example-custom-modal-styling-title"
          backdropClassName="video-backdrop"
          className="full-video-popup"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {isMobile && <div className="modal-bg-black"></div>}
            <ReactPlayer url={props.url} />
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-video"
          aria-labelledby="example-custom-modal-styling-title"
          backdropClassName="video-backdrop"
          className="full-video-popup"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {isMobile && <div className="modal-bg-black"></div>}
            <ReactPlayer
              frameborder="0"
              webkitallowfullscreen
              mozallowfullscreen
              allowfullscreen
              autoplay
              ref={vidRef}
              onPlay={trackerVideo(props.title)}
              url={props.url}
              modestbranding="1"
              controls={isMobile && isIOS ? true : false}
              muted={isMobile && isIOS ? mute : false}
              playsinline={true}
              playing={play}
              onEnded={() => {
                setHTMLVideo(false)
              }}
              width="100%"
              height="100vh"
              previewTabIndex={0}
              light={false}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

export default CustomVideo
