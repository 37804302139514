import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import ModuleSection from '@components/modules/moduleWrapper';
import ModuleTitle from '@components/modules/Title';
import ModuleText from '@components/modules/Text';
import { Stack } from "react-bootstrap";
import ButtonLink from '@components/ButtonLink';
import { StaticImage } from "gatsby-plugin-image";
import Routes from '@components/routes';

// import { fadeInFromTop } from '@components/utils/animations';

import './boxCta.scss';

const BoxCta = ({ moduleData, Button, CountryFlagStatus }) => {
    const dataDefault = {
        Title: "Start your <strong>property journey</strong> with us.",
        Text: "We are ready to support you with your property requirements."
    }
    const btnDefault = [
        { id: "1", label: "Find a Property", func: "PropertySearchModal", icon: "searchIcon" },
        { id: "2", label: "Book a Valuation", manualLink: `${Routes.Sell}${Routes.RequestValuation}`, icon: "homeIcon" },
        { id: "3", label: "List your Property", manualLink: `${Routes.ListYourProperty}`, icon: "plusIcon" }
    ]
    const moduleDataDefault = moduleData ? moduleData : dataDefault;
    const buttonDataDefault = Button.length > 0 ? Button : btnDefault;
    // const [renderComponent, setRenderComponent] = useState(false);

    // useEffect(() => {
    //   window.addEventListener("mousemove", () => {
    //     if (renderComponent === false) {
    //       setRenderComponent(true)
    //     }
    //     })
    //     window.addEventListener("touchmove", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    //     window.addEventListener("keypress", () => {
    //         if (renderComponent === false) {
    //         setRenderComponent(true)
    //         }
    //     })
    // }, [])
    return(
        <ModuleSection
            sectionClass={`box-cta section-half-grey ${moduleData?.moduleClass ? moduleData.moduleClass : "section-no-py"} ${CountryFlagStatus && "country_page__boxCta_module mt-25 mt-md-50"}  `}
            removeContainer={moduleData?.removeContainer ? moduleData.removeContainer : false}
            sectionSpacing={moduleData?.sectionSpacing}
        >
            <div className="position-relative box-cta__wrapper">
                {/* {renderComponent && */}
                <StaticImage
                    src="../../../images/pattern.jpg"
                    quality={50}
                    formats={["auto", "webp"]}
                    alt="Background Image - Exclusive Links"
                    className="pattern-img"
                />
                {/* } */}
                <div className="box-cta__wrapper__text position-relative p-32 p-md-48 p-lg-120" /*variants={fadeInFromTop}*/>
                    {moduleDataDefault?.Title &&
                        <ModuleTitle
                            title={moduleDataDefault.Title}
                            className="box-cta__title mb-8 mb-md-16"
                        />
                    }
                    {moduleDataDefault?.Text &&
                        <ModuleText
                            text={moduleDataDefault.Text}
                            className="box-cta__text mb-24 mb-md-32 mb-lg-40"
                        />
                    }
                    {buttonDataDefault.length > 0 &&
                        <Stack className="vstack gap-16 flex-md-row gap-md-24">
                            {buttonDataDefault.map((btn) => {
                                return(
                                    <ButtonLink className="box_cta_btn" key={btn?.id} enableSpan={true} {...btn} variant={btn?.variant ? btn.variant : "secondary"} />
                                )
                            })}
                        </Stack>
                    }
                </div>
            </div>
        </ModuleSection>
    )
}

export default BoxCta

export const boxCtaFragment = graphql`
    fragment BoxCtaFragment on GLSTRAPI_ComponentModulesBoxCta {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
        Button {
            id
            manualLink
            btnClass
            label
            icon
            func
            page {
                Slug
                id
                Parent {
                    Slug
                }
                Parent2 {
                    Slug
                }
                Parent3 {
                    Slug
                }
            }
        }
    }
`